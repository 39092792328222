interface apiConfigValue {
  url: string
  timeout?: number
}
type apiConfig = {
  [key: string]: apiConfigValue
}
const DELTA_REPORTING_TIMEOUT: number = parseInt(
  process.env.DELTA_REPORTING_REQUEST_TIMEOUT_MS?.toString() ?? '600000',
  10
)

const timeout = (time = '3m'): number => {
  // Regular expression to match the time format
  const timePattern = /^(\d+)(s|m|h)$/
  const match = time.match(timePattern)

  if (!match) {
    throw new Error('Invalid time format. Use formats like 10s, 3m, 1h.')
  }

  const value = parseInt(match[1], 10)
  const unit = match[2]

  let milliseconds: number

  switch (unit) {
    case 's':
      milliseconds = value * 1000
      break
    case 'm':
      milliseconds = value * 1000 * 60
      break
    case 'h':
      milliseconds = value * 1000 * 60 * 60
      break
    default:
      milliseconds = 3 * 1000 * 60 // default 3m
  }

  return milliseconds
}

const config = {
  API_AUTH_PROVIDERS: {
    url: '/api/auth/providers',
    timeout: timeout('3m')
  },
  API_LOGOUT: {
    url: '/api/auth/logout',
    timeout: timeout('3m')
  },
  API_USER_AUTH_INFO: {
    url: '/api/userinfo',
    timeout: timeout('3m')
  },
  API_USER_SET_LANGUAGE: {
    url: '/api/users/',
    timeout: timeout('3m')
  },
  API_RECLASSIFY: {
    url: '/api/reclassify',
    timeout: timeout('3m')
  },
  API_DOCUMENTS: {
    url: '/api/documents',
    timeout: timeout('3m')
  },
  API_DOCUMENTS_CLASS_CARDS: {
    url: '/api/documents/class-cards',
    timeout: timeout('3m')
  },
  API_TEMPLATES: {
    url: '/api/annotations',
    timeout: timeout('3m')
  },
  API_IDENTIFIERS: {
    url: '/api/identifiers',
    timeout: timeout('3m')
  },
  API_SMTP_CONNECTION_TEST: {
    url: '/api/auth/smtp-server-test-connection',
    timeout: timeout('3m')
  },
  API_SMTP_CONNECTION: {
    url: '/api/auth/smtp-server-configure',
    timeout: timeout('3m')
  },
  API_REPORT_CONFIG: {
    url: '/api/schedule-report',
    timeout: timeout('3m')
  },
  API_DATA_SOURCES: {
    url: '/api/datasources',
    timeout: timeout('5m')
  },
  API_EXCHANGE_DATA_SOURCES: {
    url: '/api/datasources/pst-config',
    timeout: timeout('5m')
  },
  API_DATA_SOURCE_MEMBERS: {
    url: '/api/datasources/members',
    timeout: timeout('3m')
  },
  API_DATA_SOURCE_GROUPS: {
    url: '/api/datasources/groups',
    timeout: timeout('3m')
  },
  API_DIAGNOSTICS: {
    url: '/api/diagnostics',
    timeout: 120000 // for some of the clusters it takes more than 3 sec
  },
  API_DATA_SOURCES_OAUTH: {
    url: '/api/datasources/oauth',
    timeout: timeout('3m')
  },
  API_WIDGET_ANNOTATIONS: {
    url: '/api/annotationswidget',
    timeout: timeout('3m')
  },
  API_WIDGET_FILE_TYPE: {
    url: '/api/file-types-widget',
    timeout: timeout('3m')
  },

  API_WIDGET_FILE_TYPES: {
    url: '/api/file-types-widget',
    timeout: timeout('3m')
  },
  API_PII_RECORDS: {
    url: '/api/piirecords',
    timeout: timeout('3m')
  },
  API_ANNOTATION_CLASSES: {
    url: '/api/annotationclasses',
    timeout: timeout('3m')
  },
  API_DOCUMENT_CLASSES: {
    url: '/api/documentclasses',
    timeout: timeout('3m')
  },
  API_FILES: {
    url: '/api/files',
    timeout: timeout('5m') // 5 min
  },
  API_FILES_ANNOTATE: {
    url: '/api/files/annotate',
    timeout: timeout('5m') // 5 min
  },
  API_FILES_ANONYMIZE_TEXT: {
    url: '/api/files/scan',
    timeout: timeout('5m') // 5 min
  },
  API_FILES_ANONYMIZE: {
    url: '/api/files/anonymize',
    timeout: timeout('5m') // 5 min
  },
  API_ENTITIES: {
    url: '/api/userentities',
    timeout: timeout('3m')
  },
  API_DUPLICATE_RECORDS: {
    url: '/api/duplicate-records-count',
    timeout: timeout('3m')
  },
  API_TEXT_LABELS: {
    url: '/api/get-text-label',
    timeout: timeout('3m')
  },
  API_RESIDENCIES: {
    url: '/api/residencies',
    timeout: timeout('3m')
  },
  API_DATA_LOCATIONS: {
    url: '/api/datalocations',
    timeout: timeout('3m')
  },
  API_SLACK_TOTAL_CHANNELS_USERS: {
    url: '/api/slack/metadata',
    timeout: timeout('3m')
  },
  API_VIOLATIONS: {
    url: '/api/violations',
    timeout: timeout('3m')
  },

  API_GDRIVE_SUMMARY_WIDGET: {
    url: '/api/drive-summary-widget',
    timeout: timeout('3m')
  },
  API_WIDGET_DRIVES: {
    url: '/api/drive-drives-widget',
    timeout: timeout('3m')
  },
  API_GDRIVE_USERS_WIDGET: {
    url: '/api/drive-users-widget',
    timeout: timeout('3m')
  },
  API_GDRIVE_GROUPS_WIDGET: {
    url: '/api/drive-groups-widget',
    timeout: timeout('3m')
  },
  API_DATASOURCE_USERS: {
    url: '/api/datasource-users',
    timeout: timeout('3m')
  },
  API_USERS: {
    url: '/api/users',
    timeout: timeout('3m')
  },
  API_USER_ROLES: {
    url: '/api/user-roles',
    timeout: timeout('3m')
  },
  API_INVITE_USER: {
    url: '/api/invite-users',
    timeout: timeout('5m')
  },
  API_ONEDRIVE_SUMMARY_WIDGET: {
    url: '/api/drive-summary-widget',
    timeout: timeout('3m')
  },
  API_SHAREPOINT_SUMMARY_WIDGET: {
    url: '/api/drive-summary-widget',
    timeout: timeout('3m')
  },
  API_AWSS3_SUMMARY_WIDGET: {
    url: '/api/aws-s3-summary-widget',
    timeout: timeout('3m')
  },
  API_GROUPS: {
    url: '/api/groups',
    timeout: timeout('3m')
  },
  API_DRIVES: {
    url: '/api/drives',
    timeout: timeout('3m')
  },
  API_LOCATIONS: {
    url: '/api/locations',
    timeout: timeout('3m')
  },
  API_IM_SUMMARY: {
    url: '/api/im-summary-widget',
    timeout: timeout('5m')
  },
  API_SLACK_WORKSPACE: {
    url: '/api/im-workspace-widget',
    timeout: timeout('3m')
  },
  API_SLACK_MEMBERS_WIDGET: {
    url: '/api/im-members-widget',
    timeout: timeout('3m')
  },
  API_SLACK_CHANNELS_WIDGET: {
    url: '/api/im-channels-widget',
    timeout: timeout('3m')
  },
  API_IM_MESSAGES: {
    url: '/api/im-messages',
    timeout: timeout('3m')
  },
  API_MAILS: {
    url: '/api/mails',
    timeout: timeout('5m')
  },
  API_MAIL_SUMMARY_WIDGET: {
    url: '/api/mail/summary-widget',
    timeout: timeout('3m')
  },
  API_MAIL_USERS_WIDGET: {
    url: '/api/mail/users-widget',
    timeout: timeout('3m')
  },
  API_CONVERSATIONS: {
    url: '/api/conversations',
    timeout: timeout('5m')
  },
  API_GMAIL_GROUPS_WIDGET: {
    url: '/api/mail/groups-widget',
    timeout: timeout('3m')
  },
  API_OUTLOOK_GROUPS_WIDGET: {
    url: '/api/mail/groups-widget',
    timeout: timeout('3m')
  },
  API_PROFILE_MAP: {
    url: '/api/profile_map',
    timeout: timeout('5m') // 5 min
  },
  API_ELASTIC_SEARCH_INDICES: {
    url: '/api/search-engine/indices',
    timeout: timeout('5m')
  },
  API_CLASSIFICATIONS: {
    url: '/api/classifications/pii',
    timeout: timeout('5m')
  },
  API_CLASSIFICATIONS_IDENTITY: {
    url: '/api/classifications/identity',
    timeout: timeout('5m')
  },
  API_CLASSIFICATIONS_POST: {
    url: '/api/classifications',
    timeout: timeout('5m')
  },
  API_ATTRIBUTES_WIDGET: {
    url: '/api/attributes/widget',
    timeout: timeout('3m')
  },
  API_DATA_SOURCES_WIDGET: {
    url: '/api/data-sources/widget',
    timeout: timeout('3m')
  },
  API_ALERTS: {
    url: '/api/alerts',
    timeout: timeout('3m')
  },
  API_VERSION_DETAILS: {
    url: '/api/version',
    timeout: timeout('3m')
  },
  API_SLACK_CHANNELS: {
    url: '/api/slack-channels',
    timeout: timeout('3m')
  },
  API_MS_TEAMS: {
    url: '/api/datasources/ms-teams',
    timeout: timeout('3m')
  },
  API_JIRA_PROJECTS: {
    url: '/api/jira-projects',
    timeout: timeout('3m')
  },
  API_GENERIC_DS_PROJECTS: {
    url: '/api/projects',
    timeout: timeout('3m')
  },
  API_AWS_S3_BUCKETS: {
    url: '/api/aws-s3-buckets',
    timeout: timeout('5m')
  },
  API_AWS_EVENT_BRIDGE_NOTIFICATIONS: {
    url: '/api/aws/s3/buckets/event-bridge-notifications',
    timeout: timeout('5m')
  },
  API_AZURE_SUBSCRIPTIONS: {
    url: '/api/azure-subscriptions',
    timeout: timeout('5m')
  },
  API_AZURE_STORAGE_ACCOUNTS: {
    url: '/api/azure-storage-accounts',
    timeout: timeout('5m')
  },
  API_AZURE_BLOB_CONTAINERS: {
    url: '/api/azure-blob-containers',
    timeout: timeout('5m')
  },
  API_AUTH_TOKEN: {
    url: '/api/auth/oidc/token',
    timeout: timeout('3m')
  },
  API_DATA_SOURCES_DRIVES: {
    url: '/api/datasources/drives',
    timeout: timeout('3m')
  },
  API_DATA_SHARE_POINT_SITES: {
    url: '/api/datasources/sites',
    timeout: timeout('3m')
  },
  API_ORG_DOMAINS: {
    url: '/api/datasources/microsoft/domains',
    timeout: timeout('3m')
  },
  API_REPORTS: {
    url: '/api/reports',
    timeout: timeout('8m')
  },
  API_REPORTS_ATTACHMENT: {
    url: '/api/reports/attachment',
    timeout: timeout('8m')
  },
  API_ERASED_ENTITIES: {
    url: '/api/erased_user_entities',
    timeout: timeout('3m')
  },
  API_DSR_TICKET_ATTRIBUTES: {
    url: '/api/dsr/ticket/attachment',
    timeout: timeout('5m')
  },
  API_DETECT_ATTRIBUTES: {
    url: '/api/dsr/ticket/attributes/detect',
    timeout: timeout('5m')
  },
  API_DSR_ATTACHMENT: {
    url: '/api/dsr/attachment',
    timeout: timeout('5m')
  },
  API_DSR_WEB_APP: {
    url: '/api/dsr/lightbeam_web',
    timeout: timeout('3m')
  },
  API_DSR_REQUEST: {
    url: '/api/dsr/request',
    timeout: timeout('5m')
  },
  API_DSR_REQUEST_ASSIGNEE_EMAILS: {
    url: '/api/dsr/request/assignee-emails',
    timeout: 3000
  },
  API_DSR_TICKETS_ASSIGNEE_EMAILS: {
    url: '/api/dsr/ticket/assignee-emails',
    timeout: 3000
  },
  API_COOKIE_CONSENT: {
    url: '/api/cookie-consent/',
    timeout: timeout('5m')
  },
  API_COOKIE_CONSENT_DOMAIN: {
    url: '/api/cookie-consent/domain',
    timeout: timeout('5m')
  },
  API_COOKIE_CONSENT_BANNER: {
    url: '/api/cookie-consent/banner',
    timeout: timeout('5m')
  },
  API_COOKIE_CONSENT_COOKIE_CATEGORY: {
    url: '/api/cookie-consent/category',
    timeout: timeout('5m')
  },
  API_COOKIE_CONSENT_COOKIE: {
    url: '/api/cookie-consent/cookie',
    timeout: timeout('5m')
  },
  API_COOKIE_CONSENT_SCAN: {
    url: '/api/cookie-consent/scan',
    timeout: timeout('5m')
  },
  API_CONSENT: {
    url: '/api/consent/',
    timeout: timeout('3m')
  },
  API_CONSENT_DASHBOARD: {
    url: '/api/consent/dashboard',
    timeout: timeout('3m')
  },
  API_GENERATE_DELTA_SUMMARY_REPORT: {
    url: '/api/reports/delta-report',
    timeout: DELTA_REPORTING_TIMEOUT
  },
  API_FEATURE_FLAGS: {
    url: '/api/feature/status',
    timeout: timeout('3m')
  },
  API_AUDIT_LOGS: {
    url: '/api/audit/logs',
    timeout: timeout('3m')
  },
  API_AUDIT_LOGS_DOWNLOAD_CSV: {
    url: '/api/audit/logs/download',
    timeout: timeout('3m')
  },
  API_AUDIT_LOGS_MAPPING: {
    url: '/api/audit/logs/mapping',
    timeout: timeout('3m')
  },
  API_DOC_ANALYTICS: {
    url: '/api/doc_analytics',
    timeout: timeout('3m')
  },
  API_DOMAIN_NOTIFICATION_SETTINGS: {
    url: '/api/cookie-consent/domain/notification-config',
    timeout: timeout('3m')
  },
  API_VALIDATE_REGEX: {
    url: '/api/identifiers/validate-identifier-detection-input',
    timeout: timeout('3m')
  }
}

export default config as typeof config & apiConfig
